body {
  margin: 0;
  background-color: #f1efe4;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Source Sans Pro', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Source Sans Pro'; */
  /* font-family: 'PT Serif', serif; */
  /* font-family: 'Zen Kaku Gothic New', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: calc(100vw - 34px);
  overflow-x: hidden;
  /* letter-spacing: 1px; */
}

.chat {
  /* margin: 0 auto; */
  width: 100%;
  height: 100vh;
}

.daw {
  width: 100%;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* background-color: #f1efe4; */
}

@media only screen and (max-width: 768px) {
  body {
    width: 100vw;
    overflow-x: hidden;
    letter-spacing: 0px;
  }
}
